import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Colors } from '../../../utils/constant';
import PageHeader from '../../../shared/components/pageHeader/PageHeader';
import { Grid, TextField, Theme, useMediaQuery } from '@mui/material';
import { ROUTE_MAIN_MENU } from '../../../routes/constants';
import { ReactComponent as HomeHashTagIcon } from '../../../assets/images/homeHashTag.svg';
import { useNavigate } from 'react-router-dom';
import { CancelButton, ConfirmButton } from '../../../shared/components/BasicComoponent';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '../../../assets/images/close.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { Swiper as SwiperClass } from 'swiper/types';
import moment, { invalid } from 'moment';
import { DaysOfTheWeek, Months } from '../constants';
import WhiteBoxPageWrapper from '../../../shared/WhiteBoxPageWrapper';
import { useAppSelector, useAppDispatch } from '../../../store/types';
import {
  styleRoomAvailabilitiesSelector,
  bookAppointmentIsLoadingSelector,
  bookAppointmentIsSuccessSelector,
} from '../store/bookStyleRoomSelectors';
import { bookStyleRoomActions } from '../store/bookStyleRoomSlice';
import { mainMenuDetailsSelector } from '../../mainMenu/store/mainMenuSelectors';
import TodaysDate from '../../../utils/todaysDate';
import { StyleRoomAvailabilityParams, StyleRoomAvailabilityResponse, BookAppointmentParams } from '../types';
import { fomratDate } from '../../../utils/formatDate';

const StyledTitle = styled.h2`
  font-family: NeulisAlt, sans-serif;
  font-style: italic;
  font-weight: 700;
  color: ${Colors.CharcoalGrey};
  font-size: 40px;
  line-height: normal;
  @media screen and (max-width: 599px) {
    font-size: 32px;
  }
`;

const StyledDescription = styled.p`
  font-size: 16px;
  font-weight: 300;
  line-height: 160%; /* 25.6px */
  letter-spacing: 0.64px;
  margin-top: 4px;
  color: ${Colors.CharcoalGrey};
`;

const ErrorText = styled.p`
  font-size: 14px;
  font-weight: 300;
  line-height: 160%; /* 25.6px */
  letter-spacing: 0.64px;
  margin-top: 4px;
  color: red;
`;

const StyledTextField = styled(TextField)<{ maxWidth?: number }>`
  font-size: 16px;
  font-weight: 300;
  line-height: 110%; /* 25.6px */
  border-radius: 5px;
  border: 1px solid ${Colors.CoolGrey};
  width: 100%;
  max-width: ${(props) => props.maxWidth}px;

  & .MuiInputBase-inputSizeSmall {
    padding: 0 10px;
    height: 36px;
  }

  & .MuiOutlinedInput-notchedOutline {
    outline: none;
    opacity: 1 !important;
    border: 1px solid ${Colors.CoolGrey} !important;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const StyledLabel = styled.p`
  color: ${Colors.BlackPearl};
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 4px;
  padding: 0;
`;

const LabelTitle = styled.p`
  color: ${Colors.CharcoalGrey};
  font-family: NeulisAlt, sans-serif;
  font-size: 24px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 12px;
`;

const TimeLabel = styled.p<{ isDisabled?: boolean; isHighlighted?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 25px;
  border-radius: 3px;
  background: ${(props) =>
    props.isDisabled ? Colors.SantaGrey : props.isHighlighted ? Colors.BeetleGreen : Colors.PowderBlue};
  color: ${(props) => (props.isDisabled || props.isHighlighted ? Colors.White : Colors.MineralGreen)};
  font-size: 14px;
`;

const StyledTimeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  max-width: 370px;
  column-gap: 20px;
  row-gap: 12px;
  @media screen and (max-width: 599px) {
    justify-content: center;
  }
`;

const StyledButton = styled.button<{ isDisabled?: boolean }>`
  border: none;
  background: transparent;
  cursor: ${(props) => (props.isDisabled ? 'auto' : 'pointer')};
`;

const DateContainer = styled.div<{ isHighlighted?: boolean; isDisabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 60px;
  border-radius: 3px;
  cursor: ${(props) => (props.isDisabled ? 'auto' : 'pointer')};
  flex-direction: column;
  background: ${(props) =>
    props.isDisabled ? Colors.SantaGrey : props.isHighlighted ? Colors.GreyPink : Colors.PearlBush};
  color: ${(props) => (props.isDisabled || props.isHighlighted ? Colors.White : Colors.GreyPink)};
`;

const DateTitle = styled.p`
  text-align: center;
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
  letter-spacing: 0.24px;
  line-height: 110%;
`;

const DateDescription = styled.p`
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.24px;
  line-height: 110%;
`;

const PreviousButtonContainer = styled.div`
  margin-right: 8px;
  align-self: center;
`;

const NextButtonContainer = styled.div`
  margin-left: 8px;
  align-self: center;
`;

const SwiperContainer = styled.div<{ innerWidth: number }>`
  display: flex;
  flex-direction: column;
  max-width: 400px;

  & .swiper-slide {
    width: fit-content !important;
  }

  @media screen and (max-width: 599px) {
    max-width: ${(props) => props.innerWidth}px;
  }
`;

export default function BookStyleRoomPage() {
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [swiperRef, setSwiperRef] = useState<SwiperClass>();

  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [comment, setComment] = useState('');
  const [number, setNumber] = useState('');
  const [invlidNumberText, setInvalidNumberText] = useState<string | null>(null);
  const [availableTimes, setAvailableTimes] = useState<{ label: string; isDisabled: boolean }[] | undefined>([]);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  const styleRoomAvailabilities: StyleRoomAvailabilityResponse | null = useAppSelector(styleRoomAvailabilitiesSelector);
  const mainMenuDetails = useAppSelector(mainMenuDetailsSelector);
  const bookAppointmentIsLoading = useAppSelector(bookAppointmentIsLoadingSelector);
  const bookAppointmentIsSuccess = useAppSelector(bookAppointmentIsSuccessSelector);

  const todaysDate = TodaysDate();

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setInnerWidth(newWidth);
    };

    window.addEventListener('resize', updateWindowDimensions);
    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  useEffect(() => {
    if (mainMenuDetails?.clientId && mainMenuDetails?.idLot) {
      const params: StyleRoomAvailabilityParams = {
        idClient: mainMenuDetails.clientId,
        idLot: mainMenuDetails.idLot,
      };
      dispatch(bookStyleRoomActions.fetchStyleRoomAvailability(params));
    }
  }, [mainMenuDetails, dispatch]);

  useEffect(() => {
    if (bookAppointmentIsSuccess) {
      dispatch(bookStyleRoomActions.resetBookAppointment());
      setAvailableTimes([]);
      setComment('');
      setNumber('');
      setInvalidNumberText(null);
      navigate(ROUTE_MAIN_MENU);
    }
  }, [bookAppointmentIsSuccess, navigate, dispatch]);

  function closeWithoutSaving() {
    setAvailableTimes([]);
    setComment('');
    setNumber('');
    setInvalidNumberText(null);
    navigate(ROUTE_MAIN_MENU);
  }

  const availableDates = useMemo(() => {
    if (styleRoomAvailabilities) {
      const datesArray = styleRoomAvailabilities.datums?.map((i) => {
        return {
          date: i.bookedDate,
          isDisabled: i.bookedStatus === 1,
        };
      });
      return datesArray;
    }
  }, [styleRoomAvailabilities]);

  const bookedDate = useMemo(() => {
    if (styleRoomAvailabilities) {
      for (const datum of styleRoomAvailabilities.datums) {
        const availableSlot = datum.timeSlots.find((slot) => slot.bookedStatus === 1);
        if (availableSlot) {
          return {
            bookedDate: datum.bookedDate,
            timeSlot: availableSlot.timeSlot,
          };
        }
      }
    }
    return null;
  }, [styleRoomAvailabilities]);

  function handleGoHome() {
    setAvailableTimes([]);
    navigate(ROUTE_MAIN_MENU);
  }

  function handleConfirm() {
    const phoneNumberPattern = /^(?:\+\d{11}|\+\d{12}|\d{10})$/;
    if (number.length !== 0) {
      const isNumberValid = phoneNumberPattern.test(number);
      if (isNumberValid) {
        if (selectedDate && selectedTime) {
          const payload: BookAppointmentParams = {
            IdClient: mainMenuDetails?.clientId,
            bookedDate: selectedDate?.split('T')[0],
            bookedTimeslots: selectedTime,
            comment: comment,
            phoneNumber: number,
          };
          dispatch(bookStyleRoomActions.bookAppointment(payload));
        }
      } else {
        setInvalidNumberText('Ongeldig nummer');
      }
    } else {
      setInvalidNumberText('Voer telefoonnummer in');
    }
  }

  const handlePrevious = useCallback(() => {
    swiperRef?.slidePrev();
  }, [swiperRef]);

  const handleNext = useCallback(() => {
    swiperRef?.slideNext();
  }, [swiperRef]);

  function clickDateHandler(date: string) {
    const selectedDate = styleRoomAvailabilities?.datums?.find((i) => i.bookedDate === date);
    const availableTimes: { label: string; isDisabled: boolean }[] | undefined = selectedDate?.timeSlots?.map((j) => {
      return {
        label: j.timeSlot,
        isDisabled: j.bookedStatus === 1,
      };
    });
    setAvailableTimes(availableTimes);
  }

  function handleComment(e: any) {
    if (e.target.value.length < 500) {
      setComment(e.target.value);
    }
  }

  function handlePhoneNumber(e: any) {
    setInvalidNumberText(null);
    const inputValue = e.target.value;
    const lastChar = inputValue[inputValue.length - 1];
    if (lastChar === '+' || lastChar === '-' || !isNaN(lastChar)) {
      setNumber(e.target.value);
    } else if (inputValue.length === 0) {
      setNumber('');
    }
  }

  function handleClickDate(disabled: boolean, clickedDate: string) {
    if (disabled) {
      setSelectedDate(null);
      setAvailableTimes([]);
    } else {
      setSelectedDate(clickedDate);
      setSelectedTime(null);
      clickDateHandler(clickedDate);
    }
  }

  const noOfSlides = Math.floor((innerWidth - 184) / 140) + 1;
  const slidesPerView = isSmallScreen ? noOfSlides : 3;
  const effectiveInnerWidth = 120 + 140 * (noOfSlides - 1);

  return (
    <>
      <PageHeader
        background={isSmallScreen ? Colors.White : undefined}
        buttonText={'Home'}
        buttonIcon={<HomeHashTagIcon stroke={isSmallScreen ? Colors.CharcoalGrey : Colors.White} />}
        onButtonClick={handleGoHome}
        inverted={isSmallScreen}
      />
      <WhiteBoxPageWrapper>
        <Box display={'flex'} flexDirection={'column'} height={'100%'} justifyContent={'space-between'}>
          {bookedDate ? (
            <div>
              <Box display={'flex'} flexDirection={'column'}>
                <Box display={'flex'} flexDirection={'row'} alignItems={'flex-start'} justifyContent={'space-between'}>
                  <StyledTitle>We zien je in de stijlkamer</StyledTitle>
                  {!isSmallScreen && (
                    <IconButton onClick={closeWithoutSaving} sx={{ padding: 0, marginLeft: '10px' }}>
                      <CloseIcon stroke={Colors.CharcoalGrey} width={24} />
                    </IconButton>
                  )}
                </Box>
                <StyledDescription>
                  Bedankt voor het inplannen van je afspraak in de stijlkamer. We sien je op de onderstaande datum en
                  tijd.Mocht je toch een ander moment willen plannen neem dan contact met ons op.
                </StyledDescription>
              </Box>
              <Box display={'flex'} flexDirection={'column'} mt={6}>
                <Grid container rowSpacing={2}>
                  <Grid item xs={12}>
                    <StyledLabel>{fomratDate(bookedDate.bookedDate)}</StyledLabel>
                    <StyledDescription>{bookedDate.timeSlot}</StyledDescription>
                  </Grid>
                  <Grid item xs={12}>
                    <StyledDescription>
                      Mocht je toch een ander moment willen plannen neem dan contact met ons op:
                    </StyledDescription>
                    <StyledLabel>+316 22 903 001</StyledLabel>
                  </Grid>
                </Grid>
              </Box>
            </div>
          ) : (
            <div>
              <Box display={'flex'} flexDirection={'column'}>
                <Box display={'flex'} flexDirection={'row'} alignItems={'flex-start'} justifyContent={'space-between'}>
                  <StyledTitle>Maak een afspraak</StyledTitle>
                  {!isSmallScreen && (
                    <IconButton onClick={closeWithoutSaving} sx={{ padding: 0, marginLeft: '10px' }}>
                      <CloseIcon stroke={Colors.CharcoalGrey} width={24} />
                    </IconButton>
                  )}
                </Box>
                <StyledDescription>
                  Maak een afspraak in de Stijlkamer. Kies een datum en tijd om een afspraak in te plannen.
                </StyledDescription>
              </Box>
              <Box display={'flex'} flexDirection={'column'}>
                <LabelTitle>Datum</LabelTitle>
                <Box display={'flex'} flexDirection={'row'} alignSelf="center">
                  <PreviousButtonContainer>
                    <StyledButton onClick={handlePrevious}>
                      <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 6L9 0.803848V11.1962L0 6Z" fill={Colors.GreyPink} />
                        <path d="M0 6L9 0.803848V11.1962L0 6Z" fill={Colors.GreyPink} />
                      </svg>
                    </StyledButton>
                  </PreviousButtonContainer>
                  <SwiperContainer innerWidth={effectiveInnerWidth}>
                    <Swiper
                      onSwiper={setSwiperRef}
                      // @ts-ignore
                      spaceBetween={20}
                      slidesPerView={slidesPerView}
                      // @ts-ignore
                      pagination={{
                        clickable: true,
                      }}
                      modules={[Pagination]}
                      className="mySwiper"
                    >
                      {availableDates?.map((i) => {
                        const momentDate = moment(i.date);
                        return (
                          <SwiperSlide key={i.date}>
                            <StyledButton
                              onClick={() => {
                                handleClickDate(i.isDisabled || i.date.split('T')[0] < todaysDate, i.date);
                              }}
                            >
                              <DateContainer
                                isDisabled={i.isDisabled || i.date.split('T')[0] < todaysDate}
                                isHighlighted={selectedDate === i.date}
                              >
                                <DateTitle>{DaysOfTheWeek[momentDate.day()]}</DateTitle>
                                <DateDescription>{`${momentDate.date()} ${
                                  Months[momentDate.month()]
                                }`}</DateDescription>
                              </DateContainer>
                            </StyledButton>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </SwiperContainer>
                  <NextButtonContainer>
                    <StyledButton onClick={handleNext}>
                      <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 6L0 11.1962L0 0.803848L9 6Z" fill={Colors.GreyPink} />
                        <path d="M9 6L0 11.1962L0 0.803848L9 6Z" fill={Colors.GreyPink} />
                      </svg>
                    </StyledButton>
                  </NextButtonContainer>
                </Box>
                {availableTimes && availableTimes?.length > 0 && <LabelTitle>Tijd</LabelTitle>}
                <StyledTimeContainer>
                  {availableTimes?.map((i) => (
                    <StyledButton
                      isDisabled={i.isDisabled}
                      key={i.label}
                      onClick={() => setSelectedTime(i.isDisabled ? null : i.label)}
                    >
                      <TimeLabel isDisabled={i.isDisabled} isHighlighted={i.label === selectedTime}>
                        {i.label}
                      </TimeLabel>
                    </StyledButton>
                  ))}
                </StyledTimeContainer>
              </Box>
              <Box display={'flex'} flexDirection={'column'} mt={6}>
                <Grid container rowSpacing={2}>
                  <Grid item xs={12}>
                    <StyledLabel>Opmerkingen</StyledLabel>
                    <StyledTextField
                      hiddenLabel
                      onChange={handleComment}
                      value={comment}
                      size="small"
                      maxWidth={isSmallScreen ? 1000 : 500}
                      placeholder="Vul hier informatie in met betrekking tot de afspraak..."
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <StyledLabel>Telefoonnummer *</StyledLabel>
                    <StyledTextField
                      hiddenLabel
                      type="tel"
                      onChange={handlePhoneNumber}
                      value={number}
                      size="small"
                      maxWidth={isSmallScreen ? 1000 : 200}
                      placeholder="+31xxxxxxxxx"
                    />
                    <ErrorText>{invlidNumberText}</ErrorText>
                  </Grid>
                </Grid>
              </Box>
            </div>
          )}
          {bookedDate ? (
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={isSmallScreen ? 'flex-end' : 'center'}
              height={'58px'}
              columnGap={1}
              mt={'20px'}
            >
              <ConfirmButton onClick={closeWithoutSaving}>SLUITEN</ConfirmButton>
            </Box>
          ) : (
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={isSmallScreen ? 'flex-end' : 'center'}
              height={'58px'}
              columnGap={1}
              mt={'20px'}
            >
              <CancelButton onClick={closeWithoutSaving} disabled={false}>
                Annuleren
              </CancelButton>
              <ConfirmButton
                onClick={handleConfirm}
                disabled={bookAppointmentIsLoading || !selectedDate || !selectedTime}
              >
                Bevestigen
              </ConfirmButton>
            </Box>
          )}
        </Box>
      </WhiteBoxPageWrapper>
    </>
  );
}

import React, { useEffect } from 'react';
import PageHeader from '../../../shared/components/pageHeader/PageHeader';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import { Button, Theme, useMediaQuery } from '@mui/material';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { ReactComponent as EditIcon } from '../../../assets/images/edit.svg';
import {
  // NESTED_ROUTE_FLOOR_MATERIAL_SELECTION,
  ROUTE_FLOORS,
  ROUTE_MAIN_MENU,
  ROUTE_TOTAL_PRICE_OVERVIEW,
  ROUTE_FLOOR_PREVIEW,
} from '../../../routes/constants';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as HomeHashTagIcon } from '../../../assets/images/homeHashTag.svg';
import { Colors, FloorColors } from '../../../utils/constant';
import {
  mainMenuDetailsIsLoadingSelector,
  noOfFloorsSelector,
  selectedMaterialsSelector,
  mainMenuDetailsSelector,
  totalmaterialCountSelector,
} from '../../mainMenu/store/mainMenuSelectors';
import { useAppDispatch, useAppSelector } from '../../../store/types';
import { clientIdSelector } from '../../login/store/loginSelectors';
import { BasicPageLoader } from '../../../shared/components/BasicComoponent';
import { styleSelectionActions } from '../store/styleSelectionSlice';
import IconButton from '@mui/material/IconButton';
import { genericFlowActions } from '../../genericFlow/store/genericFlowSlice';

const ContentWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  height: 100%;
  @media screen and (max-width: 599px) {
    height: 100%;
  }
`;

const TotalPriceOverviewButtonWrapper = styled.div`
  display: flex;
  width: 44px;
  height: 44px;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.White};
  border-radius: 30px;
  box-shadow: 0px 6px 30px 0px rgba(12, 27, 42, 0.18);
  position: relative;
  @media screen and (max-width: 899px) {
    top: 16px;
  }
`;

const TotalPriceOverviewButtonWrapperBigScreen = styled.div`
  display: flex;
  width: 44px;
  height: 44px;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.White};
  border-radius: 30px;
  box-shadow: 0px 6px 30px 0px rgba(12, 27, 42, 0.18);
  margin-top: 8px;
  margin-right: 14px;
  position: relative;
  @media screen and (max-width: 899px) {
    left: 24px;
    top: 16px;
  }
`;

const CardContainerDisabled = styled.div<{ $background: string }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0;
  align-items: flex-start;
  padding: 16px 32px;
  background-color: ${(props) => props.$background};
  min-height: 196px;
  width: 100%;
  margin: 5px 0 !important;
  @media screen and (max-width: 599px) {
    margin: 0 !important;
    height: 100%;
  }
`;

const CardContainer = styled(Button)<{ $background: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 0 !important;
  align-items: flex-start !important;
  padding: 16px 32px !important;
  background-color: ${(props) => props.$background} !important;
  min-height: 196px;
  width: 100%;
  margin: 5px 0 !important;
  @media screen and (max-width: 599px) {
    margin: 0 !important;
    height: 100%;
  }
`;

const TitleText = styled.p`
  color: ${Colors.White};
  font-family: NeulisAlt, sans-serif;
  font-size: 32px;
  font-style: italic;
  font-weight: 400;
  text-align: left;
  line-height: normal;
`;

const DescriptionText = styled.p`
  color: ${Colors.White};
  font-size: 16px;
  font-weight: 300;
  line-height: 160%; /* 25.6px */
  letter-spacing: 0.64px;
  text-align: left;
`;

const EditText = styled.p`
  color: ${Colors.White};
  font-size: 13px;
  font-weight: 700;
  line-height: 160%;
  letter-spacing: 0.52px;
  text-transform: uppercase;
  text-align: left;
  margin-left: 4px;
`;

const EditButton = styled.button`
  background: transparent;
  border: none;
  display: flex;
  align-self: flex-end;
  height: 20px;
  cursor: pointer;
`;

const DummyHeight = styled.div`
  height: 20px;
`;

export const BasicErrorWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const BasicErrorMessage = styled.p`
  font-size: 16px;
  text-align: center;
  margin: 0 48px;
  color: ${Colors.White};
`;

const PageWrapperFloorSelection = styled.div`
  display: flex;
  justify-content: center;
  height: max(100%, 800px);
  position: relative;
  @media screen and (max-width: 599px) {
    padding: 0;
    height: 100%;
  }
`;

const CircleDiv = styled.div`
  position: absolute;
  top: -5px;
  right: 0px;
  border-radius: 50%;
  background-color: #bd8890;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export interface CardListType {
  title: string;
  description: string;
}

const cardList: CardListType[] = [
  {
    title: 'Begane grond',
    description: 'Klik hier om jouw begane grond samen te stellen',
  },
  {
    title: 'Eerste verdieping',
    description: 'Klik hier om jouw eerste verdieping samen te stellen',
  },
  {
    title: 'Tweede verdieping',
    description: 'Klik hier om jouw tweede verdieping samen te stellen',
  },
  {
    title: 'Derde verdieping',
    description: 'Klik hier om jouw derde verdieping samen te stellen',
  },
  {
    title: 'Vierde verdieping',
    description: 'Klik hier om jouw vierde verdieping samen te stellen',
  },
];

function getBgColor(index: number, disabled: boolean) {
  const colorCode = FloorColors[index];
  if (disabled) {
    const len = colorCode.length;
    return `${colorCode.substring(0, len - 2)}0.6)`;
  }
  return colorCode;
}

export default function FloorSelectionPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const mainMenuDetailsIsLoading = useAppSelector(mainMenuDetailsIsLoadingSelector);
  const noOfFloors = useAppSelector(noOfFloorsSelector);
  const selectedMaterials = useAppSelector(selectedMaterialsSelector);
  const clientId = useAppSelector(clientIdSelector);
  const totalmaterialCount = useAppSelector(totalmaterialCountSelector);
  const mainMenuDetails = useAppSelector(mainMenuDetailsSelector);
  // const maxFloorNumber = selectedMaterials.map((i) => i.floors).sort((a, b) => b - a)?.[0];

  const isQuoteSigned = mainMenuDetails?.isQuoteSigned;

  useEffect(() => {
    dispatch(styleSelectionActions.resetSelectedDecorations());
  }, [clientId, dispatch]);

  function handleGoBack() {
    navigate(ROUTE_MAIN_MENU);
  }

  function handleFloorClick(floor: number, title: string) {
    dispatch(genericFlowActions.setSelectedFloorType(title));
    // navigate(`${ROUTE_FLOORS}/${floor + 1}/${GENERIC_FLOW_PAGE}`);
    navigate(`${ROUTE_FLOORS}/${floor + 1}/${ROUTE_FLOOR_PREVIEW}`);
  }

  function handleEditClick(floor: number, title: string) {
    dispatch(genericFlowActions.setSelectedFloorType(title));
    navigate(`${ROUTE_FLOORS}/${floor + 1}/${ROUTE_FLOOR_PREVIEW}`);
    // navigate(`${ROUTE_FLOORS}/${floor + 1}/${GENERIC_FLOW_PAGE}`);
  }

  const existingMaterialsForGroundFloor = selectedMaterials?.find((j) => j.floors === 1); // Ground floor

  function handleNavigateToTotalPriceOverview() {
    navigate(ROUTE_TOTAL_PRICE_OVERVIEW);
  }

  return (
    <>
      <PageHeader
        background={isSmallScreen && noOfFloors && !mainMenuDetailsIsLoading ? FloorColors[0] : undefined}
        disabled={isSmallScreen && !!existingMaterialsForGroundFloor}
        buttonText={'Home'}
        buttonIcon={<HomeHashTagIcon stroke={Colors.White} />}
        onButtonClick={handleGoBack}
      />
      <PageWrapperFloorSelection>
        {!isSmallScreen && selectedMaterials?.length > 0 && (
          <TotalPriceOverviewButtonWrapperBigScreen>
            {totalmaterialCount !== 0 && !isQuoteSigned && <CircleDiv>{totalmaterialCount}</CircleDiv>}
            <IconButton onClick={handleNavigateToTotalPriceOverview} sx={{ padding: '10px' }}>
              <ShoppingCartOutlinedIcon />
            </IconButton>
          </TotalPriceOverviewButtonWrapperBigScreen>
        )}
        {mainMenuDetailsIsLoading && <BasicPageLoader />}
        {!mainMenuDetailsIsLoading &&
          (noOfFloors ? (
            <ContentWrapper>
              {cardList.slice(0, noOfFloors ?? 0).map((i, index) => {
                const existingMaterials = selectedMaterials?.find((j) => j.floors === index + 1);
                if (existingMaterials) {
                  return (
                    <CardContainerDisabled key={index} $background={getBgColor(index, true)}>
                      {isSmallScreen && index === 0 && selectedMaterials?.length > 0 && (
                        <TotalPriceOverviewButtonWrapper>
                          {totalmaterialCount !== 0 && !isQuoteSigned && <CircleDiv>{totalmaterialCount}</CircleDiv>}
                          <IconButton onClick={handleNavigateToTotalPriceOverview} sx={{ padding: '10px' }}>
                            <ShoppingCartOutlinedIcon />
                          </IconButton>
                        </TotalPriceOverviewButtonWrapper>
                      )}
                      <DummyHeight />
                      <Box display={'flex'} flexDirection={'column'} sx={{ opacity: 0.6 }}>
                        <TitleText>{i.title}</TitleText>
                        <DescriptionText>{i.description}</DescriptionText>
                      </Box>
                      <EditButton onClick={() => handleEditClick(index, i.title)}>
                        <EditIcon />
                        <EditText>Bewerken</EditText>
                      </EditButton>
                    </CardContainerDisabled>
                  );
                }

                return (
                  <CardContainer
                    key={index}
                    $background={getBgColor(index, false)}
                    onClick={() => handleFloorClick(index, i.title)}
                  >
                    {isSmallScreen && index === 0 && selectedMaterials?.length > 0 && (
                      <TotalPriceOverviewButtonWrapper>
                        {totalmaterialCount !== 0 && !isQuoteSigned && <CircleDiv>{totalmaterialCount}</CircleDiv>}
                        <IconButton onClick={handleNavigateToTotalPriceOverview} sx={{ padding: '10px' }}>
                          <ShoppingCartOutlinedIcon />
                        </IconButton>
                      </TotalPriceOverviewButtonWrapper>
                    )}
                    <DummyHeight />
                    <Box display={'flex'} flexDirection={'column'}>
                      <TitleText>{i.title}</TitleText>
                      <DescriptionText>{i.description}</DescriptionText>
                    </Box>
                    <DummyHeight />
                  </CardContainer>
                );
              })}
            </ContentWrapper>
          ) : (
            <BasicErrorWrapper>
              <BasicErrorMessage>Er zijn geen verdiepingen beschikbaar voor de klant</BasicErrorMessage>
            </BasicErrorWrapper>
          ))}
      </PageWrapperFloorSelection>
    </>
  );
}

import styled from 'styled-components';
import React from 'react';
import Box from '@mui/material/Box';
import { Button, Skeleton } from '@mui/material';
import { Colors } from '../../../utils/constant';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { CancelButton } from '../BasicComoponent';

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.White};
`;

const SubTitleText = styled.p`
  color: ${Colors.GreyPink};
  font-size: 14px;
  font-weight: 700;
  line-height: 110%;
`;

const ValueText = styled.p`
  color: ${Colors.CharcoalGrey};
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  margin-top: 4px;
`;

const PriceSubTitleText = styled.p`
  color: ${Colors.BeetleGreen};
  font-size: 14px;
  font-weight: 700;
  line-height: 110%;
`;

const PriceValueText = styled.p`
  color: ${Colors.CharcoalGrey};
  font-size: 14px;
  font-weight: 400;
`;

const SubTitleContainer = styled.div`
  margin: 32px 0 0 32px;
`;

const PriceContainer = styled.div`
  margin: 32px 32px 0 0;
`;

const ContinueButton = styled(Button)<{ disabled: boolean; background: string }>`
  margin: 22px !important;
  align-self: flex-end;
  padding: 16px !important;
  border-radius: 2px !important;
  background: ${({ background }) => background} !important;
  color: ${Colors.White} !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
`;

const InnerPriceContainer = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 4px;
`;

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: Colors.White,
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 14,
    border: `1px solid ${Colors.CharcoalGrey}`,
  },
});

interface MobileViewBottomContainerProps {
  handleContinueClick: () => void;
  price: string;
  hidePrice?: boolean;
  tooltipText?: string | null;
  description: string;
  name: string;
  continueBtnDisabled?: boolean;
  handleGoBack?: () => void;
  isMaterialSelected?: boolean;
  backgroundColor?: string;
}

export default function MobileViewOptionSelectorBottomContainer({
  handleContinueClick,
  price,
  hidePrice = false,
  tooltipText,
  description,
  name,
  continueBtnDisabled,
  handleGoBack,
  isMaterialSelected,
  backgroundColor,
}: MobileViewBottomContainerProps) {
  return (
    <BottomContainer>
      <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
        <SubTitleContainer>
          <SubTitleText>{description}</SubTitleText>
          <ValueText>{name}</ValueText>
        </SubTitleContainer>
        {!hidePrice && (
          <PriceContainer>
            <PriceSubTitleText>Prijs</PriceSubTitleText>
            <InnerPriceContainer>
              <PriceValueText>{price}</PriceValueText>
              {tooltipText && (
                <CustomTooltip enterTouchDelay={0} arrow placement="top" title={tooltipText}>
                  <InfoOutlinedIcon fontSize={'inherit'} sx={{ marginLeft: '4px', color: Colors.CharcoalGrey }} />
                </CustomTooltip>
              )}
            </InnerPriceContainer>
          </PriceContainer>
        )}
      </Box>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CancelButton onClick={handleGoBack}>Ga terug</CancelButton>
        <ContinueButton
          background={backgroundColor ?? Colors.MarbleBlue}
          disabled={!!continueBtnDisabled}
          onClick={() => {
            if (!continueBtnDisabled) {
              handleContinueClick();
            }
          }}
        >
          {isMaterialSelected ? 'Volgende' : 'Sla over'}
        </ContinueButton>
      </div>
    </BottomContainer>
  );
}

export function MobileViewOptionSelectorBottomContainerSkeleton() {
  return (
    <BottomContainer>
      <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
        <SubTitleContainer>
          <Skeleton variant="rectangular" sx={{ width: '100px', height: '15px' }} />
          <Skeleton variant="rectangular" sx={{ width: '120px', height: '20px', marginTop: '4px' }} />
        </SubTitleContainer>
        <PriceContainer>
          <Skeleton variant="rectangular" sx={{ width: '100px', height: '15px' }} />
          <Skeleton variant="rectangular" sx={{ width: '120px', height: '20px', marginTop: '4px' }} />
        </PriceContainer>
      </Box>
      <Skeleton variant="rectangular" sx={{ width: '125px', height: '60px', margin: '22px', alignSelf: 'flex-end' }} />
    </BottomContainer>
  );
}

import { Box, IconButton, Skeleton, Typography } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import { Colors, ImageBaseUrl } from '../../../utils/constant';
import { CancelButton, ContinueButton, ImageLoader, Label, Value } from './Common';
import { ReactComponent as CloseIcon } from '../../../assets/images/close.svg';
import { GetFloorImageResponse } from '../types';
import { TransformWrapper, TransformComponent, useControls } from 'react-zoom-pan-pinch';

import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

interface FloorImageDetailsDesktopProps {
  floorImageIsLoading: boolean;
  floorImageData: GetFloorImageResponse | null;
  handleClose: () => void;
  handleContinue: () => void;
}

const FloorImageDetailsDesktop: React.FC<FloorImageDetailsDesktopProps> = ({
  floorImageIsLoading,
  floorImageData,
  handleClose,
  handleContinue,
}) => {
  // Implement the component logic here

  const Controls = () => {
    const { zoomIn, zoomOut, resetTransform } = useControls();
    return (
      <Box sx={{ position: 'absolute', bottom: 0, right: 0 }}>
        <IconButton onClick={() => zoomIn()}>
          <ZoomInIcon />
        </IconButton>
        <IconButton onClick={() => zoomOut()}>
          <ZoomOutIcon />
        </IconButton>
        <IconButton onClick={() => resetTransform()}>
          <RestartAltIcon />
        </IconButton>
      </Box>
    );
  };

  return (
    <>
      <LeftContainer>
        {floorImageIsLoading || !floorImageData ? (
          <ImageLoader>
            <Skeleton variant="rectangular" sx={{ width: '100%', height: '100%' }} />
          </ImageLoader>
        ) : (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              backgroundColor: Colors.AshGrey,
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <TransformWrapper
              centerOnInit
              minScale={0.5} // Minimum scale to prevent the image from becoming too small
              maxScale={3} // Maximum scale for zooming in
              initialScale={1} // Initial scale to fit the container
            >
              <Controls />
              <TransformComponent>
                <FloorPlanImage src={`${ImageBaseUrl + floorImageData?.floorImageBlobId}`} />
              </TransformComponent>
            </TransformWrapper>
          </Box>
        )}
      </LeftContainer>

      <RightContainer>
        <TopContainer>
          <Box display={'flex'} flexDirection={'row'} alignItems={'flex-start'} justifyContent={'space-between'}>
            <StyledTitle>Dit is jouw woning</StyledTitle>
            <IconButton onClick={handleClose} sx={{ padding: 0, marginLeft: '10px' }}>
              <CloseIcon stroke={Colors.White} width={24} />
            </IconButton>
          </Box>
          <Description>{floorImageData?.description}</Description>
        </TopContainer>
        <Box display={'flex'} flexDirection={'column'} height={'100%'} justifyContent={'space-between'}>
          {floorImageData && (
            <>
              <Box>
                <DataWrapper>
                  <Label>Vloeroppervlakte</Label>
                  <Value>{floorImageData.totalSqMeter} m2</Value>
                </DataWrapper>
                <DataWrapper>
                  <Label>Aantal ramen</Label>
                  <Value>{floorImageData.numberOfWindows}</Value>
                </DataWrapper>
              </Box>
            </>
          )}
          <FooteWrapper>
            <CancelButton onClick={handleClose}>Ga terug</CancelButton>
            <ContinueButton onClick={handleContinue} isSmallScreen={false}>
              Ga verder
            </ContinueButton>
          </FooteWrapper>
        </Box>
      </RightContainer>
    </>
  );
};

export default FloorImageDetailsDesktop;

const LeftContainer = styled(Box)({
  width: '60%',
  maxWidth: '550px',
});

const RightContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '40%',
  height: '100%',
  backgroundColor: theme.palette.background.paper,
}));

const TopContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: theme.spacing(2),
  backgroundColor: Colors.GreyPink,
}));

const StyledTitle = styled('h2')(() => ({
  color: Colors.White,
  fontFamily: 'NeulisAlt, sans-serif',
  fontSize: '32px',
  fontStyle: 'italic',
  fontWeight: 700,
  lineHeight: '33.48px',
}));

const Description = styled(Typography)({
  fontSize: '16px',
  fontWeight: 400,
  color: Colors.White,
  lineHeight: '25.6px',
  marginTop: 10,
  letterSpacing: '4%',
});

const DataWrapper = styled(Box)({
  marginTop: '35px',
  marginLeft: '20px',
});

const FooteWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '32px 22px',
});

const FloorPlanImage = styled('img')({
  height: '600px',
});

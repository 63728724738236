export const fomratDate = (dateStr: string) => {
  const dateObj = new Date(dateStr);

  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  };

  return new Intl.DateTimeFormat('en-GB', options).format(dateObj);
};

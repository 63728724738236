import { Box, Theme, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import React, { useEffect, useMemo } from 'react';
import { Swiper as SwiperClass } from 'swiper/types';
import { Navigation, Pagination } from 'swiper/modules';
import { Colors, ImageBaseUrl } from '../../../../utils/constant';

import { ReactComponent as LeftIcon } from '../../../../assets/images/arrow-left.svg';
import { ReactComponent as RightIcon } from '../../../../assets/images/arrow-right.svg';
import { SwiperSlide, Swiper } from 'swiper/react';
import DynamicImageSelector from '../../../../shared/components/OptionSelector/DynamicImageSelector';
import OptionSelectorRightContainer from '../../../../shared/components/OptionSelector/OptionSelectorRightContainer';
import { prepareProductData } from '../../util';
import { Product, ProductGroup, ProductSwipeData, SelectionType } from '../../types';
import priceFormatter from '../../../../utils/priceFormatter';
import MobileViewOptionSelectorTopContainer from '../../../../shared/components/OptionSelector/MobileViewOptionSelectorTopContainer';
import MobileViewOptionSelectorBottomContainer from '../../../../shared/components/OptionSelector/MobileViewOptionSelectorBottomContainer';
import { useDispatch } from 'react-redux';
import { genericFlowActions } from '../../store/genericFlowSlice';

interface ProductSelectorProps {
  flowData: ProductGroup[];
  curruntProductGroup: ProductGroup;
  curruntProduct: Product;
  selections: SelectionType;
  onSwipeChange?: (swiper: SwiperClass) => void;
  onClickSelection: (product: Product, selected: boolean) => void;
  onClickContinue: () => void;
  onClickGoBack: () => void;
  onClose: () => void;
}

const ProductSelector: React.FC<ProductSelectorProps> = ({
  flowData,
  curruntProductGroup,
  curruntProduct,
  selections,
  onSwipeChange,
  onClickSelection,
  onClickContinue,
  onClickGoBack,
  onClose,
}) => {
  const dispatch = useDispatch();

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    dispatch(genericFlowActions.setMobilePageHeaderColor(Colors.GreyPink));
    return () => {
      dispatch(genericFlowActions.setMobilePageHeaderColor(Colors.MarbleBlue));
    };
  }, []);

  const curruntProductSwipableData = useMemo(
    () => prepareProductData(flowData)[curruntProductGroup.id]?.swiperData ?? [],
    [flowData, curruntProductGroup.id],
  );

  const checkIsProductSelected = (): boolean => {
    const curruntProductGroupId = curruntProductGroup.id;
    return !!selections && selections[curruntProductGroupId]?.product?.id === curruntProduct?.id;
  };

  return (
    <>
      {isMobile && (
        <MobileViewOptionSelectorTopContainer
          title={`Kies jouw ${curruntProductGroup.name}`}
          subTitle={curruntProductGroup.description}
          bgColor={Colors.GreyPink}
        />
      )}
      <ImageSwiperContainer>
        <div className="swiper-button image-swiper-button-prev">
          <LeftIcon />
        </div>
        <div className="swiper-button image-swiper-button-next">
          <RightIcon />
        </div>

        <Swiper
          loop={true}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Navigation]}
          className="mySwiper"
          onRealIndexChange={onSwipeChange}
          navigation={{
            nextEl: '.image-swiper-button-next',
            prevEl: '.image-swiper-button-prev',
          }}
        >
          {curruntProductSwipableData &&
            curruntProductSwipableData.map((product: ProductSwipeData) => {
              const encodedBlobImg = encodeURIComponent(product.idImage);
              return (
                <SwiperSlide key={product.id}>
                  {!product.idImage ? (
                    <div>Image Not available</div>
                  ) : (
                    <DynamicImageSelector
                      image={`${ImageBaseUrl + encodedBlobImg}`}
                      checked={checkIsProductSelected()}
                      setChecked={(selected) => onClickSelection(product, selected)}
                      hasBrochure={product.brochure}
                      productId={product.id}
                    />
                  )}
                </SwiperSlide>
              );
            })}
        </Swiper>
      </ImageSwiperContainer>
      {isMobile ? (
        <MobileViewOptionSelectorBottomContainer
          handleContinueClick={onClickContinue}
          price={`${priceFormatter(curruntProduct.price)}`}
          description={curruntProduct.name}
          name={curruntProductGroup.name}
          tooltipText={
            curruntProduct.isDefaultPrice
              ? 'De prijs is gebaseerd op alle vloermaterialen op deze verdieping. Dit kan later worden aangepast.'
              : null
          }
          handleGoBack={onClickGoBack}
          isMaterialSelected={checkIsProductSelected()}
          backgroundColor={Colors.GreyPink}
        />
      ) : (
        <OptionSelectorRightContainer
          title={`Kies jouw ${curruntProductGroup.name}`}
          subTitle={curruntProductGroup.description}
          handleClose={onClose}
          handleContinueClick={onClickContinue}
          handleGoBack={onClickGoBack}
          price={`${priceFormatter(curruntProduct.price)}`}
          description={curruntProduct.description}
          name={curruntProduct.name}
          tooltipText={
            curruntProduct.isDefaultPrice
              ? 'De prijs is gebaseerd op alle vloermaterialen op deze verdieping. Dit kan later worden aangepast.'
              : null
          }
          bgColor={Colors.GreyPink}
          isMaterialSelected={checkIsProductSelected()}
        />
      )}
    </>
  );
};

export default ProductSelector;

const ImageSwiperContainer = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 60%;
  max-width: 550px;
  height: 100%;
  background-color: ${Colors.Mercury};
  @media screen and (max-width: 599px) {
    width: 100%;
    height: 100%;
    max-width: unset;
  }
`;

import { useAppDispatch, useAppSelector } from '../../../store/types';
import { useNavigate } from 'react-router-dom';
import { Skeleton, Theme, useMediaQuery } from '@mui/material';
import { ROUTE_FLOOR_SELECTION, ROUTE_MAIN_MENU } from '../../../routes/constants';
import PageHeader from '../../../shared/components/pageHeader/PageHeader';
import { Colors } from '../../../utils/constant';
import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import { ReactComponent as HomeHashTagIcon } from '../../../assets/images/homeHashTag.svg';
import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as CloseIcon } from '../../../assets/images/close.svg';
import priceFormatter from '../../../utils/priceFormatter';
import { getCurrentFloor, getReadableFloorName } from '../../../utils/getReadableFloorName';
import { totalPriceOverviewActions } from '../store/totalPriceOverviewSlice';
import { clientIdSelector } from '../../login/store/loginSelectors';
import { idLotSelector, mainMenuDetailsSelector } from '../../mainMenu/store/mainMenuSelectors';
import {
  cancelSelectionForTotalIsLoadingSelector,
  // cancelSelectionForTotalIsSuccessSelector,
  signQuoteIsLoadingSelector,
  signQuoteIsSuccessSelector,
  totalPriceOverviewIsLoadingSelector,
  totalPriceOverviewSelector,
} from '../store/totalPriceOverviewSelectors';
import { CancelButton, ConfirmButton, ErrorMessageWrapper } from '../../../shared/components/BasicComoponent';
import WhiteBoxPageWrapper from '../../../shared/WhiteBoxPageWrapper';
import CancelationPopup from '../../styleSelection/components/CancelationPopup';

const DecorationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Separator = styled.div`
  background-color: ${Colors.Platinum};
  height: 1px;
  margin-top: 8px;
  @media screen and (max-width: 599px) {
    display: none;
  }
`;

const DecorationLeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const EmptyMessage = styled.p`
  font-size: 16px;
  text-align: center;
  margin: 0 48px;
  color: black;
`;

const DecorationRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const SummaryContainer = styled.div<{ $maxHeight: string }>`
  max-height: ${(props) => props.$maxHeight};
  overflow-y: auto;
  padding-right: 10px;

  ::-webkit-scrollbar {
    display: flex;
    -webkit-appearance: none;
    width: 10px;
    border-radius: 10px;
    background-color: rgba(75, 124, 138, 0.2);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${Colors.BeetleGreen};
  }

  @media screen and (max-width: 599px) {
    max-height: unset;
    overflow-y: unset;
    padding-right: unset;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const DecorationTitle = styled.p`
  color: ${Colors.CharcoalGrey};
  font-family: NeulisAlt, sans-serif;
  font-size: 24px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  margin-left: -2px;
  @media screen and (max-width: 599px) {
    //font-weight: normal;
  }
`;

const DecorationFloor = styled.p`
  color: ${Colors.CharcoalGrey};
  font-size: 16px;
  font-weight: 700;
  line-height: 160%; /* 25.6px */
  letter-spacing: 0.64px;
  margin-top: 10px;
  @media screen and (max-width: 599px) {
    margin-top: 16px;
  }
`;

const DecorationMaterial = styled.p`
  color: ${Colors.CharcoalGrey};
  font-size: 16px;
  font-weight: 300;
  line-height: 160%; /* 25.6px */
  letter-spacing: 0.64px;
  margin-top: 10px;
  @media screen and (max-width: 599px) {
    margin-top: 0;
  }
`;

const DecorationInstallation = styled.p`
  color: ${Colors.CharcoalGrey};
  font-size: 16px;
  font-weight: 300;
  line-height: 160%; /* 25.6px */
  letter-spacing: 0.64px;
  margin-top: 8px;
  @media screen and (max-width: 599px) {
    margin-top: 0;
  }
`;

const DecorationPrice = styled.p`
  color: ${Colors.BeetleGreen};
  font-style: italic;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
`;

const DecorationTotalPrice = styled.p`
  color: ${Colors.BeetleGreen};
  font-style: italic;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
`;

const StyledTitle = styled.h2`
  font-family: NeulisAlt, sans-serif;
  font-style: italic;
  font-weight: 700;
  color: ${Colors.CharcoalGrey};
  font-size: 40px;
  line-height: normal;
  @media screen and (max-width: 599px) {
    font-size: 32px;
  }
`;

const StyledDescription = styled.p`
  font-size: 16px;
  font-weight: 300;
  line-height: 160%; /* 25.6px */
  letter-spacing: 0.64px;
  margin-top: 4px;
  margin-bottom: 32px;
  color: ${Colors.CharcoalGrey};
`;

const WinkelmandButtonContainer = styled.div`
  display: flex;
  justify-content: end;
`;

const SpanText = styled.span`
  text-decoration: underline;
  font-size: 16px;
  font-weight: 300;
  color: #bd8890;
  cursor: pointer;
`;

function TotalPriceOverviewPageSkeleton() {
  return (
    <Box display="flex" flexDirection="column">
      <Skeleton variant="rectangular" sx={{ width: '200px', height: '30px' }} />
      <Skeleton variant="rectangular" sx={{ width: '200px', height: '25px', marginTop: '10px' }} />
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Skeleton variant="rectangular" sx={{ width: '200px', height: '24px', marginTop: '10px' }} />
        <Skeleton variant="rectangular" sx={{ width: '50px', height: '24px', marginTop: '10px' }} />
      </Box>
      <Skeleton variant="rectangular" sx={{ width: '200px', height: '30px', marginTop: '32px' }} />
      <Skeleton variant="rectangular" sx={{ width: '200px', height: '25px', marginTop: '10px' }} />
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Skeleton variant="rectangular" sx={{ width: '200px', height: '24px', marginTop: '10px' }} />
        <Skeleton variant="rectangular" sx={{ width: '50px', height: '24px', marginTop: '10px' }} />
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between" mt="35px">
        <Skeleton variant="rectangular" sx={{ width: '200px', height: '30px', marginTop: '10px' }} />
        <Skeleton variant="rectangular" sx={{ width: '75px', height: '30px', marginTop: '10px' }} />
      </Box>
      <Skeleton variant="rectangular" sx={{ width: '200px', height: '25px', marginTop: '16px' }} />
    </Box>
  );
}

export default function TotalPriceOverviewPage() {
  const dispatch = useAppDispatch();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [maxHeight, setMaxHeight] = useState(window.innerHeight - 467);
  const [openModal, setOpenModal] = useState(false);
  const clientId = useAppSelector(clientIdSelector);
  const idLot = useAppSelector(idLotSelector);
  const totalPriceOverviewIsLoading = useAppSelector(totalPriceOverviewIsLoadingSelector);
  const signQuoteIsLoading = useAppSelector(signQuoteIsLoadingSelector);
  const signQuoteIsSuccess = useAppSelector(signQuoteIsSuccessSelector);
  const cancelSelectionForTotalIsLoading = useAppSelector(cancelSelectionForTotalIsLoadingSelector);
  // const cancelSelectionForTotalIsSuccess = useAppSelector(cancelSelectionForTotalIsSuccessSelector);
  const mainMenuDetails = useAppSelector(mainMenuDetailsSelector);

  const isQuoteSigned = mainMenuDetails?.isQuoteSigned;

  useEffect(() => {
    if (signQuoteIsSuccess) {
      dispatch(totalPriceOverviewActions.resetSignQuote());
      navigate(ROUTE_FLOOR_SELECTION);
    }
  }, [dispatch, signQuoteIsSuccess, navigate]);

  // useEffect(() => {
  //   if (cancelSelectionForTotalIsSuccess) {
  //     dispatch(totalPriceOverviewActions.resetCancelSelectionForTotal());
  //     navigate(ROUTE_FLOOR_SELECTION);
  //   }
  // }, [dispatch, cancelSelectionForTotalIsSuccess, navigate]);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newHeight = window.innerHeight;
      setMaxHeight(Math.max(newHeight - 467, 100));
    };

    window.addEventListener('resize', updateWindowDimensions);
    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  // Change: now data is based on api/mijnMainMenu
  // useEffect(() => {
  //   if (isQuoteSigned) {
  //     dispatch(
  //       totalPriceOverviewActions.fetchTotalPriceOverview({
  //         clientId: clientId,
  //         idLot: idLot,
  //       }),
  //     );
  //   } else {
  //     dispatch(totalPriceOverviewActions.fetchTotalPriceOverviewFailure());
  //   }
  // }, [clientId, dispatch, idLot, isQuoteSigned]);

  function handleGoHome() {
    navigate(ROUTE_MAIN_MENU);
  }

  function handleClose() {
    navigate(ROUTE_FLOOR_SELECTION);
  }

  function handleCancelSavedData(cancelTotal: number) {
    if (cancelTotal === 1) {
      dispatch(totalPriceOverviewActions.cancelSelectionForTotal({ clientId, idLot }));
    }
    navigate(ROUTE_FLOOR_SELECTION);
  }

  function handleConfirm() {
    dispatch(totalPriceOverviewActions.signQuote({ clientId }));
  }

  const totalPrice = mainMenuDetails?.totalPrice;
  const floorDecorations = (mainMenuDetails?.selectedMaterials || []).filter((i) => i.productGroupCode === 1);
  const windowDecorations = (mainMenuDetails?.selectedMaterials || []).filter((i) => i.productGroupCode === 2);
  const curtainDecorations = (mainMenuDetails?.selectedMaterials || []).filter((i) => i.productGroupCode === 3);

  const dataNotAvailable =
    floorDecorations.length === 0 && windowDecorations.length === 0 && curtainDecorations.length === 0;

  return (
    <>
      <PageHeader
        background={isSmallScreen ? Colors.White : undefined}
        buttonText={'Home'}
        buttonIcon={<HomeHashTagIcon stroke={isSmallScreen ? Colors.CharcoalGrey : Colors.White} />}
        onButtonClick={handleGoHome}
        inverted={isSmallScreen}
      />
      <WhiteBoxPageWrapper>
        <Box display={'flex'} flexDirection={'column'} height={'100%'} justifyContent={'space-between'}>
          <Box display={'flex'} flexDirection={'column'}>
            <Box display={'flex'} flexDirection={'row'} alignItems={'flex-start'} justifyContent={'space-between'}>
              <StyledTitle>Prijsopgave</StyledTitle>
              {!isSmallScreen && (
                <IconButton onClick={handleClose} sx={{ padding: 0, marginLeft: '10px' }}>
                  <CloseIcon stroke={Colors.CharcoalGrey} width={24} />
                </IconButton>
              )}
            </Box>
            <StyledDescription>
              Hier vind je de totale prijsopgave voor jouw gekozen producten. Na het bevestigen van de prijsopgave zul
              je een offerte ontvangen.
            </StyledDescription>

            {totalPriceOverviewIsLoading ? (
              <SummaryContainer $maxHeight={`${maxHeight}px`}>
                <TotalPriceOverviewPageSkeleton />
              </SummaryContainer>
            ) : (
              <>
                {dataNotAvailable ? (
                  <ErrorMessageWrapper>
                    <EmptyMessage>Er is geen materiaal geselecteerd.</EmptyMessage>
                  </ErrorMessageWrapper>
                ) : (
                  <SummaryContainer $maxHeight={`${maxHeight}px`}>
                    <Box display={'flex'} flexDirection={'column'} gap={'32px'}>
                      {floorDecorations.length > 0 && (
                        <div>
                          <DecorationTitle>Vloer</DecorationTitle>
                          {[...floorDecorations]
                            .sort((a, b) => a.floors - b.floors)
                            .map((deco) => (
                              <div key={deco.floors}>
                                <DecorationContainer>
                                  <DecorationLeftWrapper>
                                    <DecorationFloor>
                                      {getReadableFloorName(getCurrentFloor(deco.floors.toString()))}
                                    </DecorationFloor>
                                    <DecorationMaterial>{deco.productName}</DecorationMaterial>
                                  </DecorationLeftWrapper>
                                  <DecorationRightWrapper>
                                    <DecorationPrice>{priceFormatter(deco.price)}</DecorationPrice>
                                  </DecorationRightWrapper>
                                </DecorationContainer>
                                <Separator />
                              </div>
                            ))}
                        </div>
                      )}
                      {windowDecorations.length > 0 && (
                        <div>
                          <DecorationTitle>Raamdecoratie</DecorationTitle>
                          {[...windowDecorations]
                            .sort((a, b) => a.floors - b.floors)
                            .map((deco) => (
                              <div key={deco.floors}>
                                <DecorationContainer key={deco.floors}>
                                  <DecorationLeftWrapper>
                                    <DecorationFloor>
                                      {getReadableFloorName(getCurrentFloor(deco.floors.toString()))}
                                    </DecorationFloor>
                                    <DecorationMaterial>{deco.productName}</DecorationMaterial>
                                  </DecorationLeftWrapper>
                                  <DecorationRightWrapper>
                                    <DecorationPrice>{priceFormatter(deco.price)}</DecorationPrice>
                                  </DecorationRightWrapper>
                                </DecorationContainer>
                                <Separator />
                              </div>
                            ))}
                        </div>
                      )}
                      {curtainDecorations.length > 0 && (
                        <div>
                          <DecorationTitle>Gordijnen</DecorationTitle>
                          {[...curtainDecorations]
                            .sort((a, b) => a.floors - b.floors)
                            .map((deco) => (
                              <div key={deco.floors}>
                                <DecorationContainer>
                                  <DecorationLeftWrapper>
                                    <DecorationFloor>
                                      {getReadableFloorName(getCurrentFloor(deco.floors.toString()))}
                                    </DecorationFloor>
                                    <DecorationMaterial>{deco.productName}</DecorationMaterial>
                                  </DecorationLeftWrapper>
                                  <DecorationRightWrapper>
                                    <DecorationPrice>{priceFormatter(deco.price)}</DecorationPrice>
                                  </DecorationRightWrapper>
                                </DecorationContainer>
                                <Separator />
                              </div>
                            ))}
                        </div>
                      )}
                      <div>
                        {!dataNotAvailable && (
                          <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                            <DecorationTitle>Totaal:</DecorationTitle>
                            <DecorationTotalPrice>{priceFormatter(totalPrice)}</DecorationTotalPrice>
                          </Box>
                        )}
                        {!dataNotAvailable && <Separator />}
                        {!dataNotAvailable && <DecorationInstallation>Inclusief installatie</DecorationInstallation>}
                      </div>
                    </Box>
                  </SummaryContainer>
                )}
                {!dataNotAvailable && !isQuoteSigned && (
                  <WinkelmandButtonContainer>
                    <SpanText onClick={() => setOpenModal(true)}>Winkelmand leegmaken</SpanText>
                  </WinkelmandButtonContainer>
                )}
              </>
            )}
          </Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={isSmallScreen ? 'flex-end' : 'center'}
            height={'58px'}
            columnGap={1}
            mt={'20px'}
          >
            <CancelButton
              onClick={() => handleCancelSavedData(0)}
              disabled={
                dataNotAvailable || cancelSelectionForTotalIsLoading || totalPriceOverviewIsLoading || isQuoteSigned
              }
            >
              Annuleren
            </CancelButton>
            <ConfirmButton
              onClick={handleConfirm}
              disabled={dataNotAvailable || signQuoteIsLoading || totalPriceOverviewIsLoading || isQuoteSigned}
            >
              Bevestigen
            </ConfirmButton>
          </Box>
        </Box>
      </WhiteBoxPageWrapper>
      <CancelationPopup
        openModal={openModal}
        closeModal={() => setOpenModal(false)}
        clickOkButton={() => handleCancelSavedData(1)}
      />
    </>
  );
}

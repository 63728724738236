import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import CreateAccountModal from '../components/CreateAccountModal';
import PageHeader from '../../../shared/components/pageHeader/PageHeader';
import { Colors } from '../../../utils/constant';
import { Formik } from 'formik';
import { StyledForm } from '../../../shared/components/StyledForm';
import { Button, CircularProgress, Grid } from '@mui/material';
import TextInputField from '../../../shared/components/TextInputField';
import Box from '@mui/material/Box';
import { loginActions } from '../store/loginSlice';
import { ROUTE_MAIN_MENU } from '../../../routes/constants';
import { LoginPayload } from '../types';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from '../../../store/types';
import { useNavigate } from 'react-router-dom';
import {
  isMultipleLotsSelector,
  clientIdSelector,
  loginDetailsHasErrorSelector,
  loginDetailsIsLoadingSelector,
  userDetailsSelector,
  activeLotsSelector,
} from '../store/loginSelectors';
import { styled as materialStyled } from '@mui/material/styles';
import TermsAndConditionModal from '../components/TermsAndConditionModal';
import ActivelotModal from '../components/ActivelotModal';

const PageTitle = styled.h1`
  color: rgb(255, 255, 255);
  font-family: NeulisAlt, sans-serif;
  font-size: 40px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
`;

const PageDescription = styled.h2`
  color: ${Colors.White};
  font-size: 16px;
  font-weight: 700;
  line-height: 160%;
  letter-spacing: 0.72px;
  margin-top: 16px;
`;

const PageExtraDescription = styled.p`
  color: ${Colors.White};
  font-size: 16px;
  font-weight: 300;
  line-height: 160%;
  letter-spacing: 0.72px;
`;

const SignupLink = styled.button`
  color: ${Colors.TitanWhite};
  font-size: 16px;
  font-weight: 300;
  line-height: 160%;
  letter-spacing: 0.64px;
  text-decoration-line: underline;
  background: transparent;
  border: none;
  width: fit-content;
  margin-top: 16px;
  cursor: pointer;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 75px 0;
  height: max(100%, 800px);
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 340px;
  @media screen and (max-width: 599px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const StyledButton = materialStyled(Button)({
  padding: '16px',
  borderRadius: '2px',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '160%',
  letterSpacing: '0.64px',
  textTransform: 'uppercase',
  color: Colors.White,
  width: '100%',
  height: '58px',
  '&.Mui-disabled': {
    color: `${Colors.White} !important`,
    background: `${Colors.GreyPink} !important`,
    // opacity: 0.6,
  },
});

const ErrorText = styled.p`
  color: ${Colors.red};
  font-size: 14px;
  text-align: left;
  margin-top: 2px;
  max-width: 300px;
`;

export default function LoginPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const clientId = useAppSelector(clientIdSelector);
  const isMultipleLots = useAppSelector(isMultipleLotsSelector);
  const activeLotsData = useAppSelector(activeLotsSelector);
  const loginDetailsIsLoading = useAppSelector(loginDetailsIsLoadingSelector);
  const loginDetailsHasError = useAppSelector(loginDetailsHasErrorSelector);
  const userDetails = useAppSelector(userDetailsSelector);
  const [showCreateAccountModal, setShowCreateAccountModal] = useState(false);
  const [showTermsAndCondition, setShowTermsAndCondition] = useState(false);
  const [loginClicked, setLoginClicked] = useState(false);
  const [showActiveLot, setShowActiveLot] = useState(true);
  const emailRef = React.useRef<string>('');
  const passwordRef = React.useRef<string>('');

  useEffect(() => {
    dispatch(loginActions.resetLoginDetails());
    setShowTermsAndCondition(false);
    setShowActiveLot(false);
  }, [dispatch]);

  useEffect(() => {
    if (isMultipleLots) {
      setShowActiveLot(true);
    } else if (isMultipleLots === false && clientId === -1) {
      const id = activeLotsData[0].idClient;
      dispatch(loginActions.setIdConst(id));
    }
  }, [activeLotsData, clientId, dispatch, isMultipleLots]);

  useEffect(() => {
    if (clientId !== -1) {
      if (userDetails?.isFirstLogin && loginClicked) {
        setShowTermsAndCondition(true);
      } else {
        navigate(ROUTE_MAIN_MENU, { replace: true });
      }
    }
  }, [clientId, navigate, userDetails, dispatch, loginClicked]);

  const initialValues: LoginPayload = {
    email: '',
    password: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('E-mail moet een geldig e-mailadres zijn').required('E-mail is vereist'),
    password: Yup.string().required('Wachtwoord is vereist'),
  });

  function handleSubmit(values: LoginPayload) {
    setLoginClicked(true);
    emailRef.current = values.email;
    passwordRef.current = values.password?.trim();
    const credentials = {
      email: emailRef.current,
      password: passwordRef.current,
    };
    dispatch(loginActions.fetchLoginDetails(credentials));
  }

  return (
    <>
      <PageHeader />
      <PageWrapper>
        <ContentWrapper>
          <PageTitle>Mijn Stijlpartner</PageTitle>
          <PageDescription>Log in binnen jouw persoonlijke omgeving</PageDescription>
          <PageExtraDescription>
            De inloggegevens heb je ontvangen per e-mail van je persoonlijke partner
          </PageExtraDescription>
          <Box mt={2}>
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
              {({ dirty, isValid, values }) => {
                if (loginDetailsHasError) {
                  if (values.email !== emailRef.current) {
                    dispatch(loginActions.resetLoginDetails());
                  }
                  if (values.password !== passwordRef.current) {
                    dispatch(loginActions.resetLoginDetails());
                  }
                }
                return (
                  <StyledForm>
                    <Grid container rowSpacing={2}>
                      <Grid item xs={12}>
                        <TextInputField
                          label={'Email'}
                          name="email"
                          required
                          placeholder={'Enter email...'}
                          type="email"
                        />
                      </Grid>
                      {loginDetailsHasError && loginDetailsHasError.includes('e-mail') && (
                        <ErrorText>{loginDetailsHasError}</ErrorText>
                      )}
                      <Grid item xs={12}>
                        <TextInputField
                          label={'Wachtwoord'}
                          name="password"
                          required
                          placeholder={'Enter password...'}
                          type="password"
                        />
                      </Grid>
                      {loginDetailsHasError && loginDetailsHasError.includes('wachtwoord') && (
                        <ErrorText>{loginDetailsHasError}</ErrorText>
                      )}
                      <Grid item xs={12}>
                        <StyledButton
                          variant="contained"
                          type="submit"
                          disabled={!dirty || !isValid || loginDetailsIsLoading}
                        >
                          {loginDetailsIsLoading ? <CircularProgress size={25} color="inherit" /> : 'Login'}
                        </StyledButton>
                      </Grid>
                    </Grid>
                  </StyledForm>
                );
              }}
            </Formik>
          </Box>
          <SignupLink onClick={() => setShowCreateAccountModal(true)}>Problemen met inloggen?</SignupLink>
          {/* <CheckBoxContainer>
            <Checkbox
              checked={checked}
              onChange={handleChange}
              {...label}
              size="small"
              sx={{
                color: Colors.White,
                padding: 0,
                '&.Mui-checked': {
                  color: Colors.White,
                },
              }}
            />
            <CheckBoxLabel>
              Ik heb de
              <StyledLink href={'https://www.stijlpartner.nl/info/privacy/'} target="_blank">
                privacyverklaring
              </StyledLink>
              gelezen en ga ermee akkoord
            </CheckBoxLabel>
          </CheckBoxContainer> */}
          {/* {needToAccept && (
            <Grid item xs={12} display={'flex'} justifyContent={'center'}>
              <ErrorText>{needToAccept}</ErrorText>
            </Grid>
          )} */}
        </ContentWrapper>
      </PageWrapper>
      <CreateAccountModal openModal={showCreateAccountModal} closeModal={() => setShowCreateAccountModal(false)} />
      <TermsAndConditionModal
        openModal={showTermsAndCondition}
        closeModal={() => setShowTermsAndCondition(false)}
        clientId={clientId}
      />
      <ActivelotModal
        openModal={showActiveLot}
        closeModal={() => {
          setShowActiveLot(false);
        }}
      />
    </>
  );
}
